<template>
  <div id="bitcoinWithdraw" class="form-bottom-row line clearfix">
    <div class="fr img-row">
      <img
        :src="
          withdrawalType == 80
            ? require('@/assets/images/withdraw/eth_icon.png')
            : require('@/assets/images/withdraw/usdc_icon.png')
        "
        alt="skrill_icon"
      />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form-list two-fields clearfix">
          <li>
            <el-form-item
              :label="withdrawalType == 80 ? $t('withdraw.eth.field.address') : $t('withdraw.usdc.field.address')"
              prop="address"
            >
              <el-input
                v-model.trim="TransferForm.address"
                id="address"
                type="text"
                maxlength="64"
                auto-complete="new-password"
                data-testid="cryptoWalletAddress"
                class="plain-input"
              />
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('common.field.imptNotes')" prop="notes">
              <ImportantNotesInput
                v-model="TransferForm.notes"
                id="notes"
                auto-complete="new-password"
                class="plain-input"
                data-testid="importantNotes"
              ></ImportantNotesInput>
            </el-form-item>
          </li>
        </ul>
        <div class="alert-info">
          <img class="alert_info_icon" src="@/assets/images/withdraw/info.png" alt="info_img" />
          <p>{{ $t('deposit.crypto.note') }}</p>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'CryptoCpsWithdraw',
  props: ['accountNumber', 'amount', 'withdrawalType', 'currency', 'followerResults'],
  components: { ImportantNotesInput },
  data() {
    const validateAddress = (rule, value, callback) => {
      var ethereum_address = require('ethereum-address');
      if (value === '') {
        callback(
          new Error(
            this.$t(
              this.withdrawalType == 80
                ? 'withdraw.eth.formValidation.addressReq'
                : 'withdraw.usdc.formValidation.addressReq'
            )
          )
        );
      } else if (!ethereum_address.isAddress(value)) {
        callback(
          new Error(
            this.$t(
              this.withdrawalType == 80
                ? 'withdraw.eth.formValidation.addressFormat'
                : 'withdraw.usdc.formValidation.addressFormat'
            )
          )
        );
      } else callback();
    };
    return {
      TransferForm: {
        address: '',
        notes: ''
      },
      TransferFormRules: {
        address: [
          {
            required: true,
            validator: validateAddress,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    withdrawalType(val) {
      this.$refs.TransferForm.validateField('address');
    }
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        cryptoWalletAddress: this.TransferForm.address,
        importantNotes: this.TransferForm.notes,
        currency: this.currency,
        followerResultIds: this.followerResults
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/withdraw.scss';
</style>
