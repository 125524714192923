export default {
  data() {
    return { isRememberInfo: true, isdisabled: false }
  },
  methods: {
    validateAccount(rule, value, callback) {
      if (/\s/.test(value)) {
        this.TransferForm.accountNumber = this.TransferForm.accountNumber.replace(/\s+/g, '')
        callback()
      } else if (!value || value === '') {
        callback(new Error(this.$t('common.formValidation.bankAccNumReq')))
      } else {
        callback()
      }
    },
  },
}
