<template>
  <div v-if="showHint">
    <!-- styleType_1 -->
    <div class="tipBlock" v-if="styleType === 1">
      <span>{{
        $t('withdraw.otherMethod.withdrawalFees', {
          percentageFee,
          calculatedAmount,
          currency
        })
      }}</span>
      <el-popover
        v-if="cryptoChannel.includes(withdrawalType)"
        class="popover"
        placement="right-end"
        trigger="hover"
        :content="$t('withdraw.otherMethod.amountsExceeding')"
      >
        <i class="el-icon-info" slot="reference"></i>
      </el-popover>
    </div>

    <!-- styleType_2 -->
    <div class="tipBlock" v-if="styleType === 2">
      <img class="alert_info_icon" src="@/assets/images/withdraw/info.png" alt="info_img" />
      <span>{{ commissionsText }}</span>
    </div>
  </div>
</template>

<script>
import { apiPaymentGetInfo } from '@/resource';

export default {
  name: 'FeeTip',
  props: {
    withdrawalType: Number,
    currency: String,
    styleType: Number,
    withdrawalAmount: Number,
    mt4Account: Number
  },
  data() {
    return {
      showHint: false,
      percentageFee: 0,
      calculatedAmount: 0,
      cryptoChannel: [34, 36, 80, 81, 86, 87],
      typeOneShow: [31, 32],
      typeTwoShow: [31, 32]
    };
  },
  watch: {
    withdrawalType(newWithdrawalType) {
      this.getApiPaymentGetInfo(newWithdrawalType, this.currency, this.withdrawalAmount, this.mt4Account);
    },
    withdrawalAmount(newWithdrawalAmount) {
      this.getApiPaymentGetInfo(this.withdrawalType, this.currency, newWithdrawalAmount, this.mt4Account);
    }
  },
  computed: {
    commissionsText() {
      const commissions = {
        31: 'withdraw.skrill.skrillChargeAlert',
        32: 'withdraw.neteller.netellerChargeAlert'
      };
      return this.$t(commissions[this.withdrawalType], { percentageFee: this.percentageFee });
    }
  },
  mounted() {
    this.getApiPaymentGetInfo(this.withdrawalType, this.currency, this.withdrawalAmount, this.mt4Account);
  },
  methods: {
    getApiPaymentGetInfo(type, currency, amount, account) {
      this.showHint = false;
      const getAllParameter = type && currency && amount && account;
      const shouldVisible =
        (this.styleType === 1 && !this.typeOneShow.includes(type)) ||
        (this.styleType === 2 && this.typeTwoShow.includes(type));
      if (!getAllParameter || !shouldVisible) return;

      apiPaymentGetInfo({
        withdrawType: type,
        currency: currency,
        withdrawalAmount: amount,
        mt4Account: account
      }).then(resp => {
        this.showHint = resp.data.show_hint;
        this.percentageFee = resp.data.percentage_fee;
        this.calculatedAmount = resp.data.calculated_amount;
      });
    }
  },
  amount() {
    this.getApiPaymentGetInfo(this.withdrawalType, this.currency, this.withdrawalAmount, this.mt4Account);
  }
};
</script>

<style lang="scss" scoped>
.tipBlock {
  display: flex;
  align-items: center;
  gap: 8px;

  .alert_info_icon {
    height: 20px;
  }

  .el-icon-info {
    color: #0051ff;
    font-size: 20px;
  }
}
</style>
